import request from '@/utils/request';
export var queryPlayerInfo = function queryPlayerInfo(params) {
  return request({
    url: '/user/' + params.playerId,
    method: 'get',
    params: params
  });
};
export var chargeToPlayer = function chargeToPlayer(uid, data) {
  return request({
    url: '/user/charge/' + uid,
    method: 'put',
    data: data
  });
};
export var cardRecord = function cardRecord(params) {
  return request({
    url: '/user/cardRecord/' + params.uid,
    method: 'get',
    params: params
  });
};
export var forbidPlayer = function forbidPlayer(data) {
  return request({
    url: '/user/forbid',
    method: 'post',
    data: data
  });
};
export var getPlayers = function getPlayers(data) {
  return request({
    url: '/user/getPlayers',
    method: 'post',
    data: data
  });
};
export var modifyInfo = function modifyInfo(data) {
  return request({
    url: '/user/modifyInfo',
    method: 'post',
    data: data
  });
};
export var modifySmsCode = function modifySmsCode(data) {
  return request({
    url: '/user/modifySmsCode',
    method: 'post',
    data: data
  });
};
export var chargeVip = function chargeVip(data) {
  return request({
    url: '/user/chargeVip',
    method: 'post',
    data: data
  });
};
export var addVipExp = function addVipExp(data) {
  return request({
    url: '/user/addVipExp',
    method: 'post',
    data: data
  });
};
export var nameRecord = function nameRecord(data) {
  return request({
    url: '/user/nameRecord',
    method: 'post',
    data: data
  });
};
export var queryGiveGiftList = function queryGiveGiftList(params) {
  return request({
    url: '/stat/queryGiveGiftList',
    method: 'get',
    params: params
  });
};